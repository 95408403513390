var defaultConf = require('./config-default');

module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: 'boph3.7-data-dev.mobile-spot.com/',
  // no trailing '/'
  BO_PATH: '/home/www/printemps/boph/boph3.7-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '~/printemps/boph/boph3.9-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-41991492-1',
  projectId: 1014660025549,
  // GCM/FCM send id
  appId: 'B4396-9272A',
  // pushwoosh appid
  CODEPUSH: {
    FEATURE_ENABLED: false
  }
});