import React from 'react';
import PropTypes from 'prop-types';

import { DISABLE_FAVORITE_BY_DATATYPE } from 'data/config/listConfig';

import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import './DetailFixedTitle.scss';

/**
 * @return {object}
 */
const getTitleStyle = (image) => {
  const url = `url(${getUrl(image)})`;
  return {
    backgroundImage: url,
  };
};

/**
 * @param  {boolean}  isFav
 * @param  {function} onFavClick
 * @param  {object}   labels
 */
const getFavoriteIcon = (isFav, onFavClick, labels) => {
  if (typeof onFavClick !== 'function') {
    console.error('Missing action on click on favorite icon');
    return null;
  }

  const favoriteClasses = ['clickable'];
  if (isFav === true) {
    favoriteClasses.push('is-fav');
  }

  return (
    <GenericIcon
      className={isFav ? 'full-star-fiche ' : 'empty-star-fiche ' + favoriteClasses.join(' ')}
      href="#"
      onClick={onFavClick}
      title={labels.common.addToFavorites}
      style={{
        margin: '.1em .3em 0',
        fontSize: '1.8em',
        cursor: 'pointer',
        WebkitTapHighlightColor: 'transparent',
      }}
    />
  );
};

/**
 * Title bar displayed on every 'fiche'
 * (e.g exhibitor, aircraft, event, newproduct, service...)
 *
 * @param  {string}   title
 * @param  {string}   dataType
 * @param  {boolean}  isFav        indicate if the item currently belongs to favorites
 * @param  {boolean}  hideFav      indicate if favorite icon should be hidden
 * @param  {function} onFavClick   favorite icon click handler
 * @param  {string}   image        optional: url of an icon to display before title
 * @param  {object}   labels       labels set for current language
 */
const DetailFixedTitle = (props) => (
  <div
    className={`prop-name title title-font ${
      props.children || props.hideFav !== true ? 'title-flex' : ''
    }`}
  >
    {typeof props.image === 'string' && (
      <div className="title-image" style={getTitleStyle(props.image)} />
    )}
    {typeof props.image2 === 'string' && (
      <div className="title-image-small" style={getTitleStyle(props.image2)} />
    )}
    {typeof props.image3 === 'string' && (
      <div className="title-image-small" style={getTitleStyle(props.image3)} />
    )}

    <div className="prop-desc" dangerouslySetInnerHTML={{ __html: props.title }} />

    {props.children}

    {(props.hideFav === true ? true: !DISABLE_FAVORITE_BY_DATATYPE.includes(props.dataType)) &&
      getFavoriteIcon(props.isFav, props.onFavClick, props.labels)}
  </div>
);

DetailFixedTitle.propTypes = {
  title: PropTypes.string.isRequired,
  hideFav: PropTypes.bool,
  isFav: PropTypes.bool,
  onFavClick: PropTypes.func,
  image: PropTypes.string,
  image2: PropTypes.string,
  image3: PropTypes.string,
  labels: PropTypes.object,
};

export default DetailFixedTitle;
