import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dialog from 'src/components/dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import config from 'data/config/config';

import * as actions from 'src/store/actions';
import { getBindedActions } from 'src/store/bindedActions';

import { isAndroid } from 'src/core/util/browser';
import showConfirmModal from 'src/core/util/showConfirmModal';

import './FormModal.scss';

import { isIOS, isIphoneX } from 'src/core/util/browser';
import { testEmail } from 'src/core/util/StringUtil';
import ImageUpload from 'src/components/form/ImageUpload';

import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';

import { saveBase64AsFile } from 'src/core/cordova/fileSystem';

import InputModal from 'src/components/form/InputModal';
import CheckBoxes from 'src/components/form/CheckBoxes';
import TextField from 'src/components/form/TextField';
import InputSwitch from 'src/components/form/InputSwitch';

export const COMPONENT_KEY = 'FormModal';
export const CONTAINER_DOM_ID = 'form-modal-container';
class FormModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.renderFormInput = this.renderFormInput.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.renderModalInput = this.renderModalInput.bind(this);
  }
  imagesBeingCropped = {};

  setImagesBeingCropped = (image, name) => {
    this.imagesBeingCropped = { ...this.imagesBeingCropped, [name]: image };
  };

  onValueChange(name, value) {
    let form = this.state.form ? this.state.form : {};
    form[name] = value;
    this.setState({
      form,
    });
  }
  handleChange = (e) => {
    let form = this.state.form ? this.state.form : {};
    form[e.target.name] = e.target.value;
    this.setState({
      form,
    });
  };
  handleImageChange = (image, eleName) => {
    let form = this.state.form ? this.state.form : {};
    form[eleName] = image;
    this.setState({
      form,
    });
  };
  onCloseButtonClick = (e) => {
    this.setState({
      form: {},
      formErrors: {},
    });
    const { actions } = this.props;
    actions.hideFormModal();
  };

  validateEmail(email) {
    return testEmail(String(email).toLowerCase());
  }
  componentDidUpdate(prevProps) {
    const prevForm = prevProps.form;
    const { form } = this.props;
    if (
      form &&
      form != prevForm &&
      (!this.state.form || Object.keys(this.state.form).length === 0)
    ) {
      let _form = {};
      form.fields.map((ele) => {
        _form[ele.name] = ele.value;
      });
      this.setState({
        form: _form,
      });
    }
  }
  validateForm(submit) {
    const { form, formAction, formActionPayload, labels } = this.props;
    let errors = false,
      alerts = false;
    let formErrors = this.state.formErrors ? this.state.formErrors : {};
    form.fields.map((ele, index) => {
      if (ele.validation) {
        switch (ele.validation.type) {
          case 'email':
            let email = this.state.form
              ? this.state.form[ele.name]
                ? this.state.form[ele.name]
                : ''
              : '';
            errors = formErrors[ele.name] = !this.validateEmail(email);
            break;
          case 'image':
            if (ele.mandatory && !this.state.form[ele.name]) errors = formErrors[ele.name] = true;

            Object.keys(ele.validation.params).reduce((result, param) => {
              let valid = result;
              switch (param) {
                case 'checkCrop':
                  valid = ele.validation.params[param] !== !!this.imagesBeingCropped[ele.name];
                  !valid && (alerts = true);
                  !valid &&
                    showConfirmModal({
                      text: ele.validation.alertMessage,
                      yesCb: () => {
                        this.imagesBeingCropped[ele.name] = null;
                        this.validateForm();
                      },
                      noCb: () => {},
                    });
                  break;
                case 'mime_type':
                  if (this.state.form[ele.name]) {
                    valid =
                      ele.validation.params[param].indexOf(
                        this.state.form[ele.name].metaData.contentType.split('/')[1]
                      ) !== -1;
                    if (!valid) errors = formErrors[ele.name] = true;
                  }
                  break;
                default:
                  break;
              }
              return valid ? result : valid;
            }, true);
            break;
          default:
        }
      }
    });
    if (!alerts) {
      this.setState({
        formErrors,
      });
      if (!errors) {
        formAction && formAction(this.state.form, formActionPayload);
        this.onCloseButtonClick();
      }
    }
  }

  getFormFieldByName(name) {
    return this.props.form.fields.find((ele) => ele.name === name);
  }
  renderFormInput({
    name,
    className,
    placeholder,
    label,
    // type,
    onBlur,
    readOnly,
    disabled,
    required,
    onClick,
    // onChange,
  }) {
    let value = (this.state.form && this.state.form[name]) || '';
    let element = this.getFormFieldByName(name);
    let showTick =
      (!element.prefilled && value) ||
      (element.prefilled && value !== element.prefilled.suffix);

    return (
      <div
        key={name}
        className={'clickableInput ' + (className || '')}
        readOnly={readOnly}
        disabled={disabled}
        required={required}
        onClick={(evt) => {
          this.setState({ inputModalOpened: true });
          onClick(evt);
        }}
      >
        <div className={'fm-icon ' + (showTick ? 'fm-icon-check fal fa-check' : '')} />

        <div className="fm-label-and-value">
          <div className="fm-label text-color1">{label && label}</div>
          {value ? (
            <div className="fm-value">{value}</div>
          ) : (
            placeholder && <div className="fm-input-placeholder">{placeholder}</div>
          )}
        </div>

        <div className="fm-chevron fas fa-chevron-right" />
      </div>
    );
  }
  renderModalInput({
    name,
    className,
    placeholder,
    type,
    value,
    label,
    onBlur,
    readOnly,
    disabled,
    required,
    onClick,
    onChange,
  }) {
    switch (type) {
      case 'text': // EDIT_TEXT
      case 'number': // NUMERIC
      case 'date': // DATE_TIME
      case 'email': // EMAIL
      case 'textarea': // TEXT_AREA
        return (
          <TextField
            fieldName={name}
            type={type}
            className={className}
            currentFormValue={value || ''}
            setValue={onChange}
            label={label}
            placeholder={placeholder}
            autofocus
            prefilled={this.getFormFieldByName(name).prefilled}
          />
        );

      case 'radio': // SINGLE_CHOICE
      case 'checkbox': // MULTI_CHOICE
        return (
          <CheckBoxes
            fieldName={name}
            className={className}
            currentFormValue={value || []}
            setValue={onChange}
            label={label}
            placeholder={placeholder}
            labels={this.props.labels}
            multiple={type !== 'radio'}
            values={this.parseCheckBoxValue(name)}
          />
        );

      case 'switch': // BOOLEAN - Not displayed using InputModal
        return null;

      default:
        console.error('Unexpected field type: ' + type);
        return null;
    }
  }
  getDialogMaxHeight() {
    return document.documentElement.clientHeight - this.props.keyboardHeight;
  }
  render() {
    const { isOpen, form, formAction, formActionPayload, liEl, labels, actions } = this.props;
    if (!form) return null;

    const _this = this;
    const fields = form.fields.map((ele, index) => {
      //TODO implement more fields types.
      switch (ele.type) {
        case 'inputModal':
          return (
            <InputModal
              key={ele.name}
              name={ele.name}
              type={ele.subType}
              initialValue={(this.state.form && this.state.form[ele.name]) || ''}
              readOnly={ele.isReadOnly}
              required={ele.validation}
              disabled={false}
              label={ele.label}
              placeHolder={ele.placeholder}
              fieldClassName=""
              renderClickableInput={this.renderFormInput}
              renderModalInput={this.renderModalInput}
              submit={this.onValueChange}
              hideOnSubmit
              labels={labels}
              okButtonLabel={labels.common.ok}
              className="fm-modal dialog-min-width"
              maxHeight={this.getDialogMaxHeight()}
            />
          );
        case 'input':
          return (
            <div key={'field' + index} className="form-modal-input-container">
              <div className="form-modal-input-containerIn">
                <label className="form-modal-label" htmlFor={ele.name}>
                  {ele.label + ' :'}
                </label>
                {this.state.formErrors &&
                  this.state.formErrors[ele.name] &&
                  ele.validation &&
                  ele.validation.validationMessage && (
                    <div className="form-modal-validation-message">
                      {ele.validation.validationMessage}
                    </div>
                  )}
                <input
                  type={ele.subType}
                  className={
                    'form-modal-input ' +
                    (this.state.formErrors && this.state.formErrors[ele.name]
                      ? 'form-modal-input-error'
                      : '')
                  }
                  value={(this.state.form && this.state.form[ele.name]) || ''}
                  onFocus={() => {
                    this.setState({
                      inputModalOpened: false,
                    });
                  }}
                  name={ele.name}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          );
        case 'image':
          return (
            <div key={'field ' + index} className="form-modal-input-container">
              <div className="form-modal-input-containerIn">
                {this.state.formErrors &&
                  this.state.formErrors[ele.name] &&
                  ele.validation &&
                  ele.validation.validationMessage && (
                    <div className="form-modal-validation-message">
                      {ele.validation.validationMessage}
                    </div>
                  )}
                <ImageUpload
                  key={ele.name}
                  specialPermission={ele.specialPermission}
                  localPath={
                    (this.state.form &&
                      this.state.form[ele.name] &&
                      this.state.form[ele.name].value) ||
                    ''
                  }
                  defaultImageUrl={ele.defaultImageUrl}
                  onSelect={(image) => {
                    this.imagesBeingCropped[ele.name] = null;
                    if (image) {
                      switch (ele.storageType) {
                        case 'local':
                          if (global.isCordovaContext !== true) return;
                          var contentType = image.metaData.type;
                          var folderpath = cordova.file.dataDirectory;
                          var filename = image.metaData.name;
                          let splitter = 'data:' + contentType + ';base64,';
                          var myBase64 = image.value.split(splitter)[1];
                          const metaData = {
                            contentType,
                            filename,
                          };

                          saveBase64AsFile(folderpath, filename, myBase64, contentType, (value) => {
                            const image = {
                              metaData,
                              value,
                            };
                            _this.handleImageChange(image, ele.name);
                          });
                          break;
                        default:
                          break;
                      }
                    } else {
                      _this.handleImageChange(null, ele.name);
                    }
                  }}
                  setImagesBeingCropped={(image) => this.setImagesBeingCropped(image, ele.name)}
                  requestStatus={null}
                  readOnly={false}
                  labels={labels}
                  actions={actions}
                />
              </div>
            </div>
          );
        default:
          return null;
      }
    });

    return (
      <Dialog
        open={isOpen} 
        fullScreen={false}
        onClose={actions.hideFormModal} // important, to update `open` value in all cases
        style={
          this.props.isKeyboardOpen && !this.state.inputModalOpened
            ? isIOS()
              ? { top: '-40%' }
              : { top: '-50%' }
            : { top: 0 }
        }
      >
        <div className="form-modal content">
          <div className="form-modal-body">
            <DialogTitle className="form-modal-title">{form.title}</DialogTitle>

            <div className="form-modal-subtitle">{form.subtitle}</div>
            {fields}
          </div>
          <div className="form-modal-footer">
            <div className="form-modal-buttons">
              <div
                id="form-modal-deleteButton"
                className="form-modal-button"
                onClick={this.onCloseButtonClick}
              >
                {form.labels && form.labels.cancelBtnLabel
                  ? form.labels.cancelBtnLabel
                  : labels.formModal.cancel}
              </div>
              <div
                id="form-modal-closeButton"
                className="form-modal-button"
                onClick={() => {
                  this.validateForm();
                }}
              >
                {form.labels && form.labels.sendBtnLabel
                  ? form.labels.sendBtnLabel
                  : labels.formModal.send}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

FormModal.propTypes = {
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  keyboardHeight: PropTypes.number,
  isKeyboardOpen: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormModal);
