import React from 'react';
import PropTypes from 'prop-types';

import './FlagButton.scss';

import { getCurrent as getLang } from 'src/core/Lang';

function FlagButton({ className, selectedLang, lang, labels, onClick }) {
  if (!lang) {
    return null;
  }

  const cssClasses = ['flag-select', `flag-${lang}`];

  function _onClick() {
    if (typeof onClick === 'function') {
      onClick(lang);
    }
  }
  return (
    <span
      className={getLang() === lang ? `button-lang-selected` : `button-lang`}
      title={labels ? labels.lang[lang] : ''}
      onClick={_onClick}
    >
      {lang}
    </span>
  );
}

FlagButton.propTypes = {
  className: PropTypes.string,
  lang: PropTypes.string.isRequired,
  labels: PropTypes.object,
  onClick: PropTypes.func,
  selectedLang: PropTypes.string,
};

export default FlagButton;
