module.exports = require("deepmerge")(require("./config.default.js"), {
  version: {
    codepushDeploymentVersion: {
      iOS: "",
      android: ""
    }
  },
  native: {
    appleTeamId: "2BAP3P29V2",
    name: {
      default: "Haussmann DEV"
    },
    id: "com.printemps.haussmann.dev",
    appleAppId: "1012404293"
  },
  codePush: {
    android: {
      APP_SECRET: "0f341fd6-9c44-4f50-87b7-32570e03332e",
      CodePushDeploymentKey: ""
    },
    iOS: {
      APP_SECRET: "8dfe1e66-4bb3-4402-b6b9-67bb050f37ac",
      CodePushDeploymentKey: ""
    }
  },
  web: {
    url: "boph3.9-dev.mobile-spot.com",
    deploy: {
      host: "kim1",
      user: "www",
      path: "/home/www/mobigeo/printemps"
    }
  }
});